import {createAsyncThunk} from "@reduxjs/toolkit";
import {type AxiosError} from "axios";
import {
  getCustomerRewardsInfo,
  getOrderRewardsHistoryDetails,
  getRewardsHistory,
  postEnrollCustomer,
} from "api/rewards";
import {
  ENROLL_CUSTOMER,
  GET_CUSTOMER_REWARDS_INFO,
  GET_ORDER_LOYALTY_HISTORY_DETAILS,
  GET_REWARDS_HISTORY,
  REWARDS_REDUCER,
} from "./constants";
import {
  IEnrollCustomerPayload,
  IGetCustomerRewardsInfoPayload,
  IGetOrderLoyaltyHistoryDetailsPayload,
  IGetRewardsHistoryPayload,
} from "./types";

export const getCustomerRewardsInfoThunk = createAsyncThunk(
  `${REWARDS_REDUCER}/${GET_CUSTOMER_REWARDS_INFO}`,
  async (payload: IGetCustomerRewardsInfoPayload, thunkApi) => {
    try {
      const response = await getCustomerRewardsInfo(payload);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);

export const enrollCustomerThunk = createAsyncThunk(
  `${REWARDS_REDUCER}/${ENROLL_CUSTOMER}`,
  async (payload: IEnrollCustomerPayload, thunkApi) => {
    try {
      const response = await postEnrollCustomer(payload);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);

export const getRewardsHistoryThunk = createAsyncThunk(
  `${REWARDS_REDUCER}/${GET_REWARDS_HISTORY}`,
  async (payload: IGetRewardsHistoryPayload, thunkApi) => {
    try {
      const response = await getRewardsHistory(payload);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);

export const getOrderRewardsHistoryDetailsThunk = createAsyncThunk(
  `${REWARDS_REDUCER}/${GET_ORDER_LOYALTY_HISTORY_DETAILS}`,
  async (payload: IGetOrderLoyaltyHistoryDetailsPayload, thunkApi) => {
    try {
      const response = await getOrderRewardsHistoryDetails(payload);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
