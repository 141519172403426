import {createAsyncThunk} from "@reduxjs/toolkit";
import {AUTH_REDUCER_NAME, SIGN_IN} from "state/redux/slices/auth/constants/actions";
import {signIn, type SignInResponse, type SignInBody} from "api/auth/signIn";

export const signInThunk = createAsyncThunk<SignInResponse, SignInBody>(
  `${AUTH_REDUCER_NAME}/${SIGN_IN}`,
  async ({otp}, thunkApi) => {
    try {
      const response = await signIn({otp});
      return response?.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
