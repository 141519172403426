import {useCallback, useEffect, useState} from "react";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {BUSINESS_OWNER_AUTH_TOKEN_KEY} from "utils";
import {ToastError} from "components/common";
import {useRecommendedWindows} from "components/newOrder/hooks/useRecommendedWindows.js";
import {useTransportationPreference} from "hooks/orderBuilder/delivery/useTransportationPreference";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions, orderThunks} from "state/redux/slices/order";
import {
  getCurrentCustomerAddress,
  getNearStoresData,
} from "state/redux/slices/order/selectors";
import {getDoorDashDeliveryEstimate} from "api/doordash";
import {DELIVERY_PROVIDERS, ERRORS_MESSAGES, VIEWS} from "constants/order";
import {IAddress} from "types/customer";
import {URLS} from "../../SchedulingScreen";
import {DICTIONARY} from "../../assets/dictionary";
import {DIRECTIONS, transformToInputProps} from "../../assets/utils";
import {IUseScheduling, STEPS, ScheduleWindow} from "./types";

export const useScheduling = ({
  setIsNextButtonDisabled,
  /** @deprecated Use transportationPreference instead */
  isAutoSchedule: deprecatedAutoScheduleFlag,
  bagsCount,
  setPickupDeliveryData,
  pickup,
  delivery,
  selectedServices,
  turnAround,
  gotoPickup,
  gotoDelivery,
  form,
}: IUseScheduling) => {
  const {url} = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {data: availableStores} = useSelector(getNearStoresData);
  const customerAddress = useSelector(getCurrentCustomerAddress);
  const timeZone = availableStores?.deliveryDays[0]?.timeZone;
  const zipCode = availableStores?.deliveryDays[0]?.customerZipCode;
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.INITIAL);
  const [isEstimating, setIsEstimating] = useState<boolean>(false);
  const {
    isFeatureFlagEnabled,
    isAutoScheduleDeliveryPreference,
    isTextWhenReadyPreference,
  } = useTransportationPreference();
  const isAutoSchedule = isFeatureFlagEnabled
    ? isAutoScheduleDeliveryPreference
    : deprecatedAutoScheduleFlag;

  const {recommended: recommendedPickupWindow} = useRecommendedWindows(
    VIEWS.RECOMMENDED_PICKUP
  ) as {
    recommended: ScheduleWindow;
  };
  const {recommended: recommendedDeliveryWindow} = useRecommendedWindows(
    VIEWS.RECOMMENDED_RETURN
  ) as {
    recommended: ScheduleWindow;
  };

  const getDoorDashEstimates = async (
    type: "RETURN" | "PICKUP",
    customerAddress: IAddress,
    window: ScheduleWindow
  ) => {
    if (window.type !== "DOORDASH") {
      return window;
    }

    let output = window;
    setIsEstimating(true);
    try {
      const doorDashEstimateResponse = await getDoorDashDeliveryEstimate({
        type,
        customerAddress,
        netOrderTotal: 1,
        deliveryTime: [
          DateTime.fromISO(window.startTime).valueOf(),
          DateTime.fromISO(window.endTime).valueOf(),
        ],
        storeId: window.storeId,
      });

      output = {
        ...output,
        doorDashEstimate: doorDashEstimateResponse?.data,
      };
    } catch (error) {
      toast.error(<ToastError message={ERRORS_MESSAGES.timeNotAvailable} />);
      dispatch(orderActions.filterDeliveryWindows(window));
    } finally {
      setIsEstimating(false);
    }

    return output;
  };

  const setPickupTime = (window: ScheduleWindow | null) =>
    dispatch(
      orderActions.setPickupInfo(
        Boolean(window) ? {timeZone, zipCode, isPickup: true, ...window} : null
      )
    );
  const setDeliveryTime = (window: ScheduleWindow | null) =>
    dispatch(
      orderActions.setReturnInfo(
        Boolean(window) ? {timeZone, zipCode, turnAround, ...window} : null
      )
    );

  const dropPickupTime = () => dispatch(orderActions.setPickupInfo(null));
  const dropDeliveryTime = () => dispatch(orderActions.setReturnInfo({}));

  const isAbleToSetWindow = useCallback(
    (window: ScheduleWindow): boolean =>
      Boolean(
        window &&
          !(
            (window?.type === DELIVERY_PROVIDERS.doorDash ||
              window?.type === DELIVERY_PROVIDERS.onDemand) &&
            bagsCount > 5
          )
      ),
    [bagsCount]
  );

  const getIsTurnAroundViolated = (pickupTime: string, deliveryTime: string): boolean => {
    return Boolean(
      DateTime.fromISO(pickupTime).plus({
        hours: Number(turnAround),
      }) > DateTime.fromISO(deliveryTime)
    );
  };

  const getIsDeliveryEarlierThanPickup = (pickupTime: string, deliveryTime: string) => {
    return Boolean(DateTime.fromISO(pickupTime) >= DateTime.fromISO(deliveryTime));
  };

  const setEmptyView = (type: "Pickup" | "Delivery") => {
    toast.error(<ToastError message={DICTIONARY.CAN_NOT_SET_TIME(type)} />);
    dropDeliveryTime();
    setPickupDeliveryDetailsView({} as ScheduleWindow);
  };

  const refreshDeliveryWindows = async (current: ScheduleWindow) => {
    await dispatch(
      orderThunks.getReturnWindows({
        windowsEndTime: current.startTime,
        timeZone,
        storeId:
          availableStores.ownDeliveryStore?.storeId ||
          availableStores.onDemandDeliveryStore?.storeId,
        zipCode,
        selectedServices,
        address: availableStores.address,
      })
    );

    return true;
  };

  const resetPickupDeliveryDetailsView = (type: "pickup" | "delivery") => {
    setPickupDeliveryData((state) => ({
      ...state,
      [type]: {
        ...DIRECTIONS[type],
      },
    }));
  };

  const setPickupDeliveryDetailsView = (window: ScheduleWindow) => {
    // for auto-schedule stores pickup window has autoScheduleTiming property, but not isPickup property
    const type = window.isPickup || window?.autoScheduleTiming ? "pickup" : "delivery";
    const data = transformToInputProps(window);
    const actionHandler =
      isAutoSchedule && !window.isPickup
        ? () => void 0
        : window.isPickup
          ? gotoPickup
          : gotoDelivery;

    if (data.current) {
      setPickupDeliveryData((state) => ({
        ...state,
        [type]: {
          ...state[type],
          action: actionHandler,
          isSkeleton: false,
          duration: turnAround,
          isAutoSchedule,
          ...data,
        },
      }));
    } else {
      setPickupDeliveryData((state) => ({
        ...state,
        [type]: {
          ...DIRECTIONS[type],
          action: window.isPickup ? gotoPickup : gotoDelivery,
          duration: turnAround,
          current: null,
          isSkeleton: false,
          isOnDemandDelivery: false,
          isScheduleWhenReady: true,
        },
      }));
    }
  };

  useEffect(() => {
    if (
      !!delivery &&
      delivery.key &&
      delivery.turnAround &&
      delivery.turnAround !== turnAround
    ) {
      setDeliveryTime(null);
      resetPickupDeliveryDetailsView("delivery");
    }
  }, [turnAround, delivery]);

  const isBusinessOperatorOrder = Boolean(
    sessionStorage.getItem(BUSINESS_OWNER_AUTH_TOKEN_KEY)
  );

  const shouldResetDeliveryTime = Boolean(
    pickup?.startTime &&
      delivery?.startTime &&
      // With DEL-1134 we provide the ability to violate Turn Around for OOBO
      // So for regular orders, it's important to check for Turn Around violations,
      // and for OOBOs, it's important to check if pickup is earlier than delivery
      (isBusinessOperatorOrder
        ? getIsDeliveryEarlierThanPickup(pickup.startTime, delivery.startTime)
        : getIsTurnAroundViolated(pickup.startTime, delivery.startTime))
  );

  useEffect(() => {
    let targetDelivery =
      isFeatureFlagEnabled && isTextWhenReadyPreference
        ? {}
        : (recommendedDeliveryWindow as Partial<ScheduleWindow>);

    switch (true) {
      case currentStep === STEPS.INITIAL && !!recommendedPickupWindow:
        if (isAbleToSetWindow(recommendedPickupWindow)) {
          getDoorDashEstimates(
            "PICKUP",
            customerAddress as IAddress,
            recommendedPickupWindow
          );
          setCurrentStep(STEPS.SCHEDULE_PICKUP);
        } else {
          toast.error(
            <ToastError message={DICTIONARY.CAN_NOT_SET_TIME_FOR_PICKUP_AND_DELIVERY} />
          );
          history.push(String(url).replace(URLS.CURRENT, URLS.SERVICE));
        }
        break;

      case currentStep === STEPS.SCHEDULE_PICKUP:
        let targetPickup = recommendedPickupWindow as Partial<ScheduleWindow>;
        if (!!pickup && isAbleToSetWindow(pickup)) {
          targetPickup = pickup;

          if (shouldResetDeliveryTime) {
            setDeliveryTime(null);
            resetPickupDeliveryDetailsView("delivery");
          }
        }

        targetPickup ? setPickupTime(targetPickup as ScheduleWindow) : dropPickupTime();
        setPickupDeliveryDetailsView(targetPickup as ScheduleWindow);
        if (isAutoSchedule) {
          setCurrentStep(STEPS.APPLY_AUTO_SCHEDULE);
        } else {
          refreshDeliveryWindows(targetPickup as ScheduleWindow).then((isOK) => {
            isOK
              ? setCurrentStep(STEPS.SCHEDULE_DELIVERY)
              : setCurrentStep(STEPS.SKIP_DELIVERY);
          });
        }
        break;

      case currentStep === STEPS.SKIP_DELIVERY:
        dropDeliveryTime();
        setPickupDeliveryDetailsView({} as ScheduleWindow);
        setCurrentStep(STEPS.DONE);
        break;

      case currentStep === STEPS.SCHEDULE_DELIVERY && !!recommendedDeliveryWindow:
        if (!!delivery) {
          targetDelivery = delivery;
        }

        if (!isAbleToSetWindow(targetDelivery as ScheduleWindow)) {
          setEmptyView("Delivery");
          setCurrentStep(STEPS.DONE);
          break;
        }

        if (!!targetDelivery) {
          getDoorDashEstimates(
            "RETURN",
            customerAddress as IAddress,
            targetDelivery as ScheduleWindow
          );
          setDeliveryTime(targetDelivery as ScheduleWindow);
        } else {
          dropDeliveryTime();
        }
        setPickupDeliveryDetailsView(targetDelivery as ScheduleWindow);
        setCurrentStep(STEPS.DONE);
        break;

      case currentStep === STEPS.APPLY_AUTO_SCHEDULE:
        const {autoScheduleTiming} = pickup;

        if (!isAbleToSetWindow(autoScheduleTiming as ScheduleWindow)) {
          setEmptyView("Delivery");
          setCurrentStep(STEPS.DONE);
          history.push(String(url).replace(URLS.CURRENT, URLS.SERVICE));
          break;
        }

        getDoorDashEstimates(
          "RETURN",
          customerAddress as IAddress,
          autoScheduleTiming as ScheduleWindow
        );
        setDeliveryTime(autoScheduleTiming as ScheduleWindow);
        setPickupDeliveryDetailsView(autoScheduleTiming as ScheduleWindow);
        setCurrentStep(STEPS.DONE);
        break;

      case currentStep === STEPS.DONE:
        const data = new FormData(form);
        const formData = Object.fromEntries(data.entries());

        formData?.pickup ? setIsNextButtonDisabled(false) : setIsNextButtonDisabled(true);
        break;
    }
  }, [currentStep, recommendedPickupWindow, recommendedDeliveryWindow]);

  return {
    currentStep,
    isEstimating,
    setPickupTime,
    setDeliveryTime,
    dropPickupTime,
    dropDeliveryTime,
    isAbleToSetWindow,
  };
};
