import {FC} from "react";
import Skeleton from "@mui/material/Skeleton";
import {useIsDeliveryAvailable} from "hooks/orderBuilder/useIsDeliveryAvailable";
import {useAppSelector} from "state/redux/hooks";
import {getServicesPricesFetchingStatus} from "state/redux/slices/order/selectors/servicesSelector";
import {getIsFetchingStore} from "state/redux/slices/order/selectors/storeSelectors";
import {FetchingStatus} from "types/common";
import styles from "./pricingButton.module.scss";

interface IPricingButtonProps {
  openDrawer: () => void;
}

export const PricingButton: FC<IPricingButtonProps> = ({openDrawer}) => {
  const pricesFetchingStatus = useAppSelector(getServicesPricesFetchingStatus);
  const isFetchingStore = useAppSelector(getIsFetchingStore);
  const isDeliveryAvailable = useIsDeliveryAvailable();

  if (isFetchingStore || pricesFetchingStatus === FetchingStatus.Pending) {
    return <Skeleton variant="text" className={styles.skeleton} />;
  } else if (pricesFetchingStatus === FetchingStatus.Fulfilled && isDeliveryAvailable) {
    return (
      <button className={styles.pricingButton} onClick={openDrawer}>
        See pricing
      </button>
    );
  }

  return null;
};
