import {FC, useEffect} from "react";
import {useSessionStorage} from "usehooks-ts";
import {CUSTOMER_AUTH_TOKEN_KEY} from "utils";
import {getCustomerOptOutUpdatesSms} from "api/customer";
import {ReactComponent as InfoIcon} from "assets/images/Icon_Info_TuneUp.svg";
import "./OptOutInfoBanner.styles.scss";
import {DICTIONARY} from "./dictionary";

export const enum OptOutInfoBannerType {
  badge = "badge",
  stretch = "stretch",
}

interface IOptOutInfoBanner {
  type?: OptOutInfoBannerType;
  phoneNumber?: string;
}

export const OPT_OUT_STATE_KEY = "LL:OPT_OUT_STATE" as const;

export const OptOutInfoBanner: FC<IOptOutInfoBanner> = ({
  type = OptOutInfoBannerType.stretch,
  phoneNumber,
}) => {
  const isCustomerLoggedIn = !!localStorage.getItem(CUSTOMER_AUTH_TOKEN_KEY);
  const [isCurrentVisible, setIsCurrentVisible] = useSessionStorage<boolean | null>(
    OPT_OUT_STATE_KEY,
    null
  );

  useEffect(() => {
    if (isCustomerLoggedIn) {
      getCustomerOptOutUpdatesSms().then((response) => {
        if (response.status === 200 && response.data.success) {
          const actualOptOutStatus = Boolean(response.data.optOutUpdatesSms);
          actualOptOutStatus !== isCurrentVisible &&
            setIsCurrentVisible(actualOptOutStatus);
        }
      });
    }
  }, [isCurrentVisible, setIsCurrentVisible]);

  return isCurrentVisible ? (
    <section id="opt-out-info-banner" className={type}>
      {type === OptOutInfoBannerType.stretch && <InfoIcon className="icon" />}
      <div className="content">
        <h3>
          {type === OptOutInfoBannerType.badge && <InfoIcon className="icon" />}
          {DICTIONARY.header}
        </h3>
        <p>{DICTIONARY.firstLineText(phoneNumber)}</p>
        <p>{DICTIONARY.secondLineText}</p>
      </div>
    </section>
  ) : null;
};
