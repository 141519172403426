import {Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {DELIVERY_TYPE_KEYS, ORDER_MESSAGES} from "constants/order";
import {TimeBox} from "../TimeBox";

export const AvailableDatesList = ({windows, chooseTime, selectedTime, tabName}) => (
  <>
    {windows.length ? (
      windows.map((window) => {
        return (
          <TimeBox
            key={window.key}
            boxData={window}
            chooseTime={chooseTime}
            selectedTime={selectedTime}
          />
        );
      })
    ) : (
      <div className="no-available-windows">
        <Typography className="empty-windows-title">{ORDER_MESSAGES.noTimes}</Typography>
        <Typography>
          {tabName === DELIVERY_TYPE_KEYS.OWN
            ? ORDER_MESSAGES.trySelectFlex
            : ORDER_MESSAGES.trySelectOurDrivers}
        </Typography>
      </div>
    )}
  </>
);

AvailableDatesList.propTypes = {
  windows: PropTypes.arrayOf(
    PropTypes.shape({
      dayIndex: PropTypes.number,
      deliveryFeeInCents: PropTypes.number,
      display: PropTypes.shape({
        displayDate: PropTypes.string,
        endTime: PropTypes.string,
        price: PropTypes.string,
        startTime: PropTypes.string,
      }),
      endTime: PropTypes.string,
      id: PropTypes.number,
      key: PropTypes.string,
      maxStops: PropTypes.number,
      oneWayFee: PropTypes.string,
      orderDeliveriesCount: PropTypes.number,
      recurringSubscriptionCount: PropTypes.number,
      serviceType: PropTypes.string,
      startTime: PropTypes.string,
      storeId: PropTypes.number,
      timingIndex: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  chooseTime: PropTypes.func.isRequired,
  selectedTime: PropTypes.string.isRequired,
  tabName: PropTypes.string.isRequired,
};

AvailableDatesList.defaultProps = {
  windows: [],
  selectedTime: "",
  tabName: "",
};
