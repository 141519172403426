import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";
import {type LatestOrderDetails} from "components/online-order/business/home/image-card/OrderCard.types";
import {type IBusinessSettingsData} from "state/redux/slices/business/types";
import {formatOrderServices} from "utils/order/formatOrderServices";
import {
  DELIVERY_CONFLICTS_TYPES,
  DELIVERY_PROVIDERS,
  ORDER_DELIVERY_TYPES,
} from "constants/order";
import {type IGenericReducer} from "types/common";
import {
  type IAddress,
  type ICustomer,
  type IPaymentMethod,
  type ISubscription,
} from "types/customer";
import {IService} from "types/models/Service";
import {type ITheme} from "types/theme";
import {ObjectValues} from "types/typeUtils";
import {type IApiRequests} from "./apiRequests";
import {type IOrderBuilder} from "./orderBuilder";

export enum Views {
  RECOMMENDED_PICKUP = "RECOMMENDED_PICKUP",
  ALL_WINDOWS_PICKUP = "ALL_WINDOWS_PICKUP",
  RETURN_QUESTION = "RETURN_QUESTION",
  SUBSCRIPTION_OFFER = "SUBSCRIPTION_OFFER",
  RECOMMENDED_RETURN = "RECOMMENDED_RETURN",
  ALL_WINDOWS_RETURN = "ALL_WINDOWS_RETURN",
}

export enum MandatoryOrderFields {
  PaymentToken = "paymentToken",
  ReturnMethod = "returnMethod",
  ServicePriceId = "servicePriceId",
  CustomerAddressId = "customerAddressId",
}

export enum ServiceCategoryTypes {
  DRY_CLEANING = "DRY_CLEANING",
  LAUNDRY = "LAUNDRY",
  ALTERATIONS = "ALTERATIONS",
}

export interface INashEstimate {
  estimateId: string;
  pickupTime: string;
  deliveryTime: string;
  deliveryFee: number;
  providerName: string;
  providerLogo: string;
}

export interface IDeliveryWindow {
  dayIndex: number;
  timingIndex: number;
  key: string;
  display: {
    displayDate: string;
    startTime: string;
    endTime: string;
    /** null in cases, when on demand estimate was unsuccessful */
    price: string | null;
  };
  id: number;
  maxStops: number | null;
  serviceType: string;
  orderDeliveriesCount: number;
  recurringSubscriptionCount: number;
  startTime: string;
  endTime: string;
  deliveryFeeInCents?: number;
  type: ObjectValues<typeof DELIVERY_PROVIDERS>;
  storeId: number;
  oneWayFee: string;
  doorDashEstimate?: {
    deliveryTime: number;
    deliveryFee: number;
  };
  estimate?: INashEstimate;
  isRecommended?: boolean;
  orderDeliveryCount: number;
  windowConflicts: Array<DELIVERY_CONFLICTS_TYPES>;
}

export interface IDeliveryDay {
  customerZipCode: string;
  timeZone: string;
  date: string;
  dayOfWeek: number;
  ownDelivery: IDeliveryWindow[];
  onDemandDelivery: IDeliveryWindow[];
}

export interface IReturnWindows {
  deliveryDays: IDeliveryDay[];
  onDemandIsAvailable: boolean;
  ownDeliveryIsAvailable: boolean;
}

export interface ICentsCustomerCredentials {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  id?: number;
  businessName?: string;
  isDeleted?: boolean;
  deletionInitiatedAt?: string;
}

export interface IInitialHomePageData {
  theme: ITheme;
  businessId: null | number;
  businessSettings: IBusinessSettingsData;
  customerAddress: IAddress | Record<string, unknown>;
  savedCustomerAddresses: IAddress[];
  centsCustomerCredentials: ICentsCustomerCredentials;
  subscriptions: ISubscription[];
  paymentMethods: IPaymentMethod[];
  storeId?: number;
}

export interface ITurnArounds {
  ownDeliveryStore?: {
    laundryTurnAroundInHours: number;
    dryCleaningTurnAroundInHours: number;
    turnAroundInHours: number;
  };
  onDemandDeliveryStore?: {
    laundryTurnAroundInHours: number;
    dryCleaningTurnAroundInHours: number;
    turnAroundInHours: number;
  };
}

export interface INearStores {
  onDemandDeliveryStore: {
    id?: number;
    active?: boolean;
    storeId?: number;
    state?: string;
    subsidyInCents?: number;
    returnOnlySubsidyInCents?: number;
    doorDashEnabled?: boolean;
    storeName?: string;
    recurringDiscountInPercent?: number;
    deliveryFeeInCents?: number;
  };
  ownDeliveryStore: {
    storeId?: number;
    state?: string;
    storeName?: string;
    deliveryFeeInCents?: number;
    turnAroundInHours?: number;
    recurringDiscountInPercent?: number;
    autoScheduleReturnEnabled?: boolean;
    ownDeliverySettings?: Record<string, string>;
  };
  recentCompletedStandardOrder: {
    clone?: {
      bagCount: number;
      address: IAddress;
      returnMethod: string;
      customerNotes: string | null;
      orderNotes: string | null;
      servicePriceId?: number;
      serviceModifierIds?: number[];
      orderServices?: ReturnType<typeof formatOrderServices>;
      orderDelivery: {
        pickup: {
          type: typeof ORDER_DELIVERY_TYPES.pickup;
        };
        delivery: {
          type: typeof ORDER_DELIVERY_TYPES.return;
        };
      };
    };
    details?: {
      hasDryCleaning: boolean;
      serviceModifiers: [];
    };
  };
  latestOrderDetails: LatestOrderDetails;
  deliveryDays: IDeliveryDay[];
  turnArounds: ITurnArounds;
  address: {
    googlePlaceId?: string;
  };
  /** @deprecated Use transportationPreference instead */
  autoScheduleReturnEnabled?: boolean;
  ownDeliveryIsAvailable: boolean;
  onDemandIsAvailable: boolean;
  addressTimeZone: null | string;
  customer: ICustomer;
  onDemandProviderDetails?: {
    providerName: string;
    providerLogo: string;
  };
  storeAddress: string;
  theme?: ITheme;
  availableServices?: {
    isCommercialPricingTier?: boolean;
    storeId: number;
    hasDryCleaning: boolean;
    hasLaundry: boolean;
    offerDryCleaningForDelivery: boolean;
    laundryServices: {
      service: IService;
    };
  };
}

export interface ISchedulePayload {
  currentStage: Views;
  pickup: ScheduleWindow | null;
  returnInfo: ScheduleWindow | null;
  pickupDayIndex: number;
  returnDayIndex: number;
  subscription?: ISubscription;
  turnAround?: number;
  ooboConflicts: Array<string>;
}

export interface IOrderData {
  orderBuilder: IOrderBuilder;
  apiRequests: IApiRequests;
  drawersStates: {
    isDrawerLocked: boolean;
    isModifiersDrawerOpened: boolean;
    isAddressSelectionDrawerOpened: boolean;
    isAddressDetailsDrawerOpened: boolean;
    isServicesDrawerOpened: boolean;
  };
  initialOrderData: IGenericReducer<IInitialHomePageData>;
  nearStoresData: IGenericReducer<INearStores>;
  returnWindows: IGenericReducer<IReturnWindows>;

  schedule: ISchedulePayload;

  currentCustomerAddress: IAddress | null;
  editableAddress: IAddress | null;

  isShowingAddressDetailsModal: boolean;
  isShowingVerificationModal: boolean;
}
