import {useEffect, useState} from "react";
import isEmpty from "lodash/isEmpty";
import Pusher from "pusher-js";
import {getParsedLocalStorageData} from "utils/common";
import {
  BASE_URL,
  PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER,
  CUSTOMER_AUTH_TOKEN_KEY,
} from "utils/config";

const usePusher = () => {
  const [pusherClient, setPusherClient] = useState<Pusher | null>(null);
  const customerAuthToken = getParsedLocalStorageData(CUSTOMER_AUTH_TOKEN_KEY);

  useEffect(() => {
    let pusher: Pusher | null = null;

    if (PUSHER_APP_KEY && PUSHER_APP_CLUSTER && !isEmpty(customerAuthToken)) {
      Pusher.logToConsole = true;

      pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: PUSHER_APP_CLUSTER,
        authEndpoint: `${BASE_URL}/pusher/auth`,
        auth: {
          headers: {
            authtoken: customerAuthToken,
            source: "LIVE_LINK",
          },
        },
      });

      // Connection successful.
      pusher.connection.bind("connected", function () {
        console.log("Pusher connection successful");
      });
      // Connection error
      pusher.connection.bind("error", function (error: any) {
        console.error(
          error?.error?.data?.code === 4004
            ? "Pusher connection limit error"
            : "Pusher connection error",
          error
        );
      });

      setPusherClient(pusher);
    } else {
      console.warn(
        "Required keys for Pusher are not available.",
        "Hence real time updates for machines is not available."
      );
    }

    return () => {
      if (pusher) {
        pusher.disconnect();
      }
      setPusherClient(null);
    };
  }, [customerAuthToken]);

  return pusherClient;
};

export default usePusher;
