export const OTP_CODE_LENGTH = 6;
export const PHONE_LENGTH = 10;
export const IMPORTED_PHONE_NUMBER_DIGITS_LENGTH = 11;
export const PHONE_NUMBER_PREFIX = "1";

export const PHONE_NUMBER_FORMAT_REGEXP = /^(\d{3})(\d{3})(\d{4})$/;
export const NOT_DIGITS_REGEXP = /\D/g;
export const EMAIL_REGEXP = /^([_a-zA-Z0-9-.]+)@([_a-zA-Z0-9-.]+)\.([a-zA-Z]{2,5})$/;
export const INPUT_TIP_REGEXP = /^[0-9]{0,3}([.][0-9]{1,2})?$/;
export const UPDATE_TIP_REGEXP = /^[0-9]{1,3}([.][0-9]{1,2})?$/;
export const ZIP_CODE_REGEXP = /(^\d{5}$)/;
export const FULL_NAME_REGEXP = /^([a-zA-Z'-.]+(?:\s[a-zA-Z]?)?\s[a-zA-Z'-.]+)$/;
export const DOLLAR_AMOUNT_REGEXP = /^[0-9]*([.][0-9]{1,2})?$/;

export const STATUS_TURN = {
  CREATED: "CREATED",
  ENABLED: "ENABLED",
  STARTED: "STARTED",
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
};

export const ERROR_MESSAGES = {
  REALTIME_STATUS: "An error occurred while requesting status from the device",
  CUSTOMER_BALANCE: "An error occurred while loading customer balance",
  VALIDATE_MACHINE_CODE: "An error occurred while validating machine code",
  ADD_CREDITS: "An error occurred while adding credits",
  MACHINE_CARDS: "An error occurred while fetching active machines",
  MACHINE_DETAILS: "An error occurred while fetching machine details",
  CUSTOMER_INFO: "An error occurred while fetching customer info",
  TURN_DETAILS: "An error occurred while fetching turn details",
  RUN_MACHINE: "An error occurred while starting the machine",
  MACHINE_DOES_NOT_EXIST: "This machine does not exist",
};

export enum PUSHER_EVENTS {
  DEVICE_STATUS_UPDATED = "device-status-updated",
  TURN_CREATED = "turn-created",
}

export enum ORDERABLE_TYPES {
  SERVICE_ORDER = "ServiceOrder",
  TURN = "Turn",
  INVENTORY_ORDER = "InventoryOrder",
}
