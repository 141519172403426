import {ChangeEvent, Dispatch, FC, SetStateAction} from "react";
import phoneNumberScreenTestids from "tests/mocks/testids/PhoneNumberScreen.testids";
import {OptInMarketingSmsSwitcher} from "components/common/OptInMarketingSmsSwitcher";
import {InputWithReset} from "components/common/forms/InputWithReset";
import {VerifiedCustomer} from "components/verify-account/PhoneNumberScreen/PhoneNumberScreen";
import {EN_LOCALE} from "locales/en";
import styles from "./signUpForm.module.scss";

export const SignUpForm: FC<
  {
    showInvalidEmailStatus: boolean;
    onHandleChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    setState: Dispatch<SetStateAction<Omit<VerifiedCustomer, "isVerified">>>;
    onEmailChanged: (event: ChangeEvent<HTMLTextAreaElement & HTMLInputElement>) => void;
    cleanEmail: () => void;
  } & Omit<VerifiedCustomer, "isVerified">
> = ({
  firstName,
  lastName,
  email,
  optOutMarketingSms,
  showInvalidEmailStatus,
  onHandleChange,
  onEmailChanged,
  setState,
  cleanEmail,
}) => {
  return (
    <form className={styles.signUpForm}>
      <InputWithReset
        label={EN_LOCALE.label.firstName}
        name="firstName"
        onChange={onHandleChange}
        value={firstName || ""}
        autoFocus
        className={styles.controlGroup}
        onReset={() => setState((prevState) => ({...prevState, firstName: ""}))}
        inputProps={{
          className: styles.formInput,
          "data-testid": phoneNumberScreenTestids.firstNameInput,
        }}
        labelClassName={styles.formLabel}
      />
      <InputWithReset
        label={EN_LOCALE.label.lastName}
        name="lastName"
        onChange={onHandleChange}
        value={lastName || ""}
        className={styles.controlGroup}
        onReset={() => setState((prevState) => ({...prevState, lastName: ""}))}
        inputProps={{
          className: styles.formInput,
          "data-testid": phoneNumberScreenTestids.lastNameInput,
        }}
        labelClassName={styles.formLabel}
      />
      <InputWithReset
        label={EN_LOCALE.label.email}
        name="email"
        onChange={onEmailChanged}
        value={email || ""}
        onReset={cleanEmail}
        className={styles.controlGroup}
        inputProps={{
          className: styles.formInput,
          "data-testid": phoneNumberScreenTestids.emailInput,
        }}
        labelClassName={styles.formLabel}
        error={showInvalidEmailStatus}
      />
      <OptInMarketingSmsSwitcher
        checked={optOutMarketingSms}
        name="optOutMarketingSms"
        onChange={onHandleChange}
      />
    </form>
  );
};
