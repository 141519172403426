import {useChooseAutoScheduleTime as useChooseAutoScheduleTimeWithNash} from "components/newOrder/hooks/nash/useChooseAutoScheduleTime";
import {useChooseTime as useChooseTimeWithNash} from "components/newOrder/hooks/nash/useChooseTime";

export const useChooseTime = ({setIsMainButtonDisabled, isAutoSchedule, isPickup}) => {
  const chooseNashAutoScheduleTime = useChooseAutoScheduleTimeWithNash({
    setIsMainButtonDisabled,
    isPickup,
  });
  const chooseRegularNashTime = useChooseTimeWithNash({
    setIsMainButtonDisabled,
    isPickup,
  });
  const chooseNashTime = isAutoSchedule
    ? chooseNashAutoScheduleTime
    : chooseRegularNashTime;

  return chooseNashTime;
};
