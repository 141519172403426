import {useState, ChangeEvent} from "react";
import {TextField} from "@material-ui/core";
import {toast} from "react-toastify";
import {Flex, Text, Button} from "rebass/styled-components";
import {DockModal, ToastError} from "components/common";
import {tipSelectionStyles} from "components/common/TipSelection/styles";
import theme from "constants/themes/theme";
import styles from "./styles";

interface ICustomAmountProps {
  addNewAmount: (amount: number) => void;
  showModal: boolean;
  toggleShowModal: (status: boolean) => void;
}

const AVAILABLE_CREDIT_MIN_AMOUNT = 5;
const EXCEPT_DIGITS_POINT_REGEX = /[^0-9.]/g;

const CustomAmount = ({addNewAmount, showModal, toggleShowModal}: ICustomAmountProps) => {
  const [amountState, setAmountState] = useState<number | null>();
  const monetaryClasses = tipSelectionStyles();

  const handlePromoCodeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInput = Number(
      Number(event.target.value.replace(EXCEPT_DIGITS_POINT_REGEX, "")).toFixed(2)
    );

    setAmountState(newInput);
  };

  const closeModal = () => {
    setAmountState(null);
    toggleShowModal(!showModal);
  };

  const sendNewAmountCredit = () => {
    if (amountState) {
      if (amountState < AVAILABLE_CREDIT_MIN_AMOUNT) {
        toast.error(
          <ToastError
            message={`Your balance cannot be less than $${AVAILABLE_CREDIT_MIN_AMOUNT.toFixed(
              2
            )}.`}
          />
        );
        return;
      }

      addNewAmount(amountState);
      closeModal();
    }
  };

  return (
    <DockModal
      header="Custom Amount"
      isOpen={showModal}
      toggle={() => {
        closeModal();
      }}
    >
      <Flex sx={styles.wrapperModal}>
        <Flex sx={styles.wrapperContent}>
          <Text sx={styles.normalText}>
            Please enter the custom amount you would like to add to your credit balance.{" "}
            <Text sx={styles.boldText}>
              Minimum ${AVAILABLE_CREDIT_MIN_AMOUNT.toFixed(2)}
            </Text>
            .
          </Text>
          <TextField
            className={monetaryClasses.inputTypeNum}
            color="primary"
            variant="outlined"
            fullWidth
            autoFocus
            label="Custom Amount"
            type="text"
            value={amountState || ""}
            onChange={handlePromoCodeChange}
            InputProps={{
              lang: "en-US",
              startAdornment: <div className={monetaryClasses.inputAdornment}>$</div>,
            }}
            inputProps={{
              style: {
                color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
                fontSize: 18,
                lineHeight: "125%",
                fontWeight: 400,
              },
              lang: "en-US",
              inputMode: "decimal",
              pattern: "[0-9]{0,3}([.][0-9]{1,2})?",
            }}
          />
        </Flex>
        <Button onClick={sendNewAmountCredit} sx={styles.saveButton}>
          Save
        </Button>
      </Flex>
    </DockModal>
  );
};

export default CustomAmount;
