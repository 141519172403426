import {initialApiRequestState} from "state/redux/constants/initialApiRequestState";
import {type IAuthInitialData} from "../types/initialData";
import {Stages} from "../types/stages";

const initialData: IAuthInitialData = {
  currentStage: Stages.PHONE_NUMBER,

  user: {
    phoneNumber: null,
    firstName: null,
    isVerified: null,
    lastName: null,
    email: null,
    optOutMarketingSms: null,
  },
  drawersStates: {
    isAuthDrawerOpened: false,
  },
  apiRequests: {
    phoneNumberVerification: initialApiRequestState,
    otpRequest: initialApiRequestState,
    verifyOtp: initialApiRequestState,
    createCustomer: initialApiRequestState,
  },
};

export default initialData;
