import {useEffect, useState} from "react";
import {TextField, Button, CircularProgress} from "@material-ui/core";
import PropTypes from "prop-types";
import {Drawer} from "components/common/drawers/Drawer";
import {EN_LOCALE} from "locales/en";

const ApplyPromoDockModal = ({isOpen, toggle, onSubmit, promoName, isLoading}) => {
  const [promoCodeState, setPromoCodeState] = useState(promoName);

  useEffect(() => {
    isOpen && setPromoCodeState(promoName || "");
  }, [isOpen]);

  return (
    <Drawer
      title={EN_LOCALE.messages.applyPromoCode}
      isShowingDrawer={isOpen}
      closeDrawer={toggle}
    >
      <div className="apply-promo-dock-modal-wrapper">
        <TextField
          label={EN_LOCALE.label.promoCode}
          type="text"
          fullWidth
          variant="outlined"
          style={{width: "80%", fontSize: "1rem"}}
          value={promoCodeState}
          onChange={(event) => setPromoCodeState(event.target.value)}
        />
        <Button
          onClick={() => onSubmit(promoCodeState)}
          variant="contained"
          color="primary"
          className="footer-button"
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress color="inherit" size={14} /> : null}
        >
          {EN_LOCALE.button.addPromo}
        </Button>
      </div>
    </Drawer>
  );
};

ApplyPromoDockModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  promoName: PropTypes.string,
  isLoading: PropTypes.bool,
};

ApplyPromoDockModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  onSubmit: () => {},
  promoName: "",
  isLoading: false,
};

export default ApplyPromoDockModal;
