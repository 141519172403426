import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {fetchReferralProgramInfo, validateReferralCode} from "api/referral";
import {
  GET_REFERRAL_PROGRAM_INFO,
  REFERRAL_REDUCER,
  GET_VALIDATE_REFERRAL_CODE,
} from "./constants";

export const getReferralProgramInfoThunk = createAsyncThunk(
  `${REFERRAL_REDUCER}/${GET_REFERRAL_PROGRAM_INFO}`,
  async (payload: {businessId: number; centsCustomerId: number}, thunkApi) => {
    try {
      const response = await fetchReferralProgramInfo(payload);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);

export const getValidateReferralCodeThunk = createAsyncThunk(
  `${REFERRAL_REDUCER}/${GET_VALIDATE_REFERRAL_CODE}`,
  async (
    payload: {referralCode: string; businessId: number | string; centsCustomerId: number},
    thunkApi
  ) => {
    try {
      const response = await validateReferralCode(payload);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
