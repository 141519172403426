import {useState} from "react";
import {useSelector} from "react-redux";
import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {
  getCurrentCustomerAddress,
  getNearStoresData,
} from "state/redux/slices/order/selectors";
import {DELIVERY_PROVIDERS} from "constants/order";
import {
  ScheduleWindowWithPickup,
  useEstimateAndSetDelivery,
} from "./useEstimateAndSetDelivery";

interface IArgs {
  setIsMainButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isPickup: boolean;
}

export const useChooseAutoScheduleTime = ({setIsMainButtonDisabled}: IArgs) => {
  const dispatch = useAppDispatch();
  const {data: availableStores} = useSelector(getNearStoresData);
  const currentCustomerAddress = useSelector(getCurrentCustomerAddress);
  const customerAddressId = currentCustomerAddress?.id;
  const [isPickupEstimating, setIsPickupEstimating] = useState(false);
  const [isReturnDeliveryEstimating, setIsReturnDeliveryEstimating] = useState(false);

  const {estimateAndSetDelivery: estimateAndSetPickup} = useEstimateAndSetDelivery({
    setIsEstimating: setIsPickupEstimating,
    customerAddressId: Number(customerAddressId),
  });

  const {estimateAndSetDelivery: estimateAndSetReturnDelivery} =
    useEstimateAndSetDelivery({
      setIsEstimating: setIsReturnDeliveryEstimating,
      customerAddressId: Number(customerAddressId),
    });

  const chooseTime = async (windowData: ScheduleWindow) => {
    const timeZone = availableStores?.deliveryDays[0]?.timeZone;
    const zipCode = availableStores?.deliveryDays[0]?.customerZipCode;
    setIsMainButtonDisabled(false);

    let isPickupSuccessful = true;
    let isReturnSuccessful = true;

    await Promise.all([
      (async () => {
        if (windowData.type !== DELIVERY_PROVIDERS.ownDriver) {
          const {isSuccessful} = await estimateAndSetPickup({
            windowData: {...windowData, isPickup: true} as ScheduleWindow,
            timeZone,
            zipCode,
          });
          isPickupSuccessful = isSuccessful;
        } else {
          dispatch(
            orderActions.setPickupInfo({
              timeZone,
              zipCode,
              isPickup: true,
              ...windowData,
            })
          );
        }
      })(),
      (async () => {
        if (windowData.autoScheduleTiming?.type !== DELIVERY_PROVIDERS.ownDriver) {
          const {isSuccessful} = await estimateAndSetReturnDelivery({
            windowData: {
              ...windowData.autoScheduleTiming,
              pickupWindow: {...windowData, isPickup: true},
            } as ScheduleWindowWithPickup,
            timeZone,
            zipCode,
          });
          isReturnSuccessful = isSuccessful;
        } else {
          dispatch(
            orderActions.setReturnInfo({
              timeZone,
              zipCode,
              ...windowData.autoScheduleTiming,
            })
          );
        }
      })(),
    ]);

    return isPickupSuccessful && isReturnSuccessful;
  };

  return {chooseTime, isEstimating: isPickupEstimating || isReturnDeliveryEstimating};
};
