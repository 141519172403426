import {type PayloadAction} from "@reduxjs/toolkit";
import {ServiceCategoryTypes, type IOrderData} from "state/redux/slices/order/types";
import {setCategorySelection} from "state/redux/slices/order/utils/setCategorySelection";

export const setIsDryCleaningCategorySelected = (
  state: IOrderData,
  action: PayloadAction<boolean>
) => {
  const newValue = action.payload;
  setCategorySelection({
    state,
    newValue,
    categoryType: ServiceCategoryTypes.DRY_CLEANING,
  });
};
