import {
  IAccountCarePreferencesFormData,
  IAccountCarePreferencesFormOutput,
} from "components/account/AccountCarePreferences/assets/AccountCarePreferencesForm/types";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {updateCustomerNotes} from "api/customer";
import {updateCustomerOptionSelectionsScope} from "api/online-order";
import {EmptyObject} from "types/common";
import {ICustomer} from "types/customer";

export const useOrderPreferencesUpdating = ({
  preferencesData,
  storeId,
  customerInfo,
  businessId,
}: {
  preferencesData: Pick<IAccountCarePreferencesFormData, "preferences"> | null;
  storeId?: number;
  customerInfo: ICustomer | EmptyObject;
  businessId: number | null;
}) => {
  const dispatch = useAppDispatch();

  const updateCarePreferences = async (
    data: Partial<IAccountCarePreferencesFormOutput>
  ): Promise<void> => {
    if (!preferencesData || !storeId || !customerInfo.id) {
      return;
    }
    const preferencesIds = preferencesData.preferences.map((preference) => preference.id);
    const filteredData: Record<number, number | number[]> = {};

    Object.keys(data).forEach((key) => {
      const normalizedKey = Number(key.split(":")[0]);
      if (preferencesIds.includes(Number(normalizedKey))) {
        const normalizedValue = Number(String(data[key]).split(":")[0]);
        const isMultiple = String(data[key]).split(":")[3] === "multi";

        const targetDataItem = filteredData[normalizedKey];

        if (targetDataItem) {
          filteredData[normalizedKey] = Array.isArray(targetDataItem)
            ? [...(targetDataItem as number[]), normalizedValue]
            : [targetDataItem, normalizedValue];
        } else {
          filteredData[normalizedKey] = isMultiple ? [normalizedValue] : normalizedValue;
        }
      }
    });
    await updateCustomerNotes(storeId, customerInfo.id, {
      notes: data.carePreferencesNote,
      isHangDrySelected: data.isHangDrySelected || false,
      hangDryInstructions: data.hangDryCustomerInstructions || "",
    });

    await updateCustomerOptionSelectionsScope({
      preferenceOptions: filteredData,
      businessId,
    });

    dispatch(orderActions.setCustomerNotes(data?.carePreferencesNote || ""));
    dispatch(orderActions.setIsHangDrySelected(data?.isHangDrySelected || false));
    dispatch(
      orderActions.setHangDryInstructions(data?.hangDryCustomerInstructions || "")
    );
  };

  return {
    updateCarePreferences,
  };
};
