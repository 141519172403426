import {useState} from "react";
import useToggle from "hooks/useToggle";
import ApplyPromoDockModal from "./ApplyPromoDockModal";
import ApplyPromoItem from "./ApplyPromoItem";

const ApplyPromoWithDockModal = ({currentPromoName, onApply}) => {
  const [isValidating, setIsValidating] = useState(false);
  const {isOpen: showPromoModal, toggle: toggleShowPromoModal} = useToggle();

  const onDockModalSubmit = async (promoName) => {
    setIsValidating(true);

    const isValid = promoName && (await onApply({promoName}));

    setIsValidating(false);

    isValid && toggleShowPromoModal();
  };

  return (
    <>
      <ApplyPromoItem promoName={currentPromoName} onClick={toggleShowPromoModal} />
      <ApplyPromoDockModal
        toggle={toggleShowPromoModal}
        isOpen={showPromoModal}
        onSubmit={onDockModalSubmit}
        promoName={currentPromoName}
        isLoading={isValidating}
      />
    </>
  );
};

export default ApplyPromoWithDockModal;
