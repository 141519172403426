import cx from "classnames";
import PropTypes from "prop-types";
import {SmallWarningIcon} from "assets/images";
import "./styles.scss";

const errorToastOptions = {
  style: {
    background: "#FCEEEA",
    borderRadius: "12px",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },
  closeButton: false,
  icon: () => <img height={24} width={24} alt="warning icon" src={SmallWarningIcon} />,
  draggablePercent: 20,
};

const ToastError = ({
  primaryMessage = "Something went wrong",
  secondaryMessage = "",
  message = "",
}) => {
  return (
    <div className={cx("toast-wrapper", {"with-description": secondaryMessage})}>
      <span className="message-section">
        {message ? (
          <p className="common-message">{message}</p>
        ) : (
          <>
            <p className="primary-message">{primaryMessage}</p>
            <p className="secondary-message">{secondaryMessage}</p>
          </>
        )}
      </span>
    </div>
  );
};

ToastError.propTypes = {
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
};

ToastError.defaultProps = {
  primaryMessage: "Something went wrong",
  secondaryMessage: "",
};

export {ToastError, errorToastOptions};
