import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {fetchBusinessCapabilities} from "api/business";
import {EN_LOCALE} from "locales/en";
import {ApiError} from "types/api";

interface UseGetBusinessCapabilitiesPayload {
  businessId?: number | null;
  centsCustomerId: number | null;
}

export interface UseGetBusinessCapabilitiesOutput {
  hasSelfServe: boolean;
  hasDelivery: boolean;
}

export const useGetBusinessCapabilities = ({
  businessId,
  centsCustomerId,
}: UseGetBusinessCapabilitiesPayload) => {
  const {isSuccess, data} = useQuery<unknown, ApiError, UseGetBusinessCapabilitiesOutput>(
    {
      queryKey: ["useGetBusinessCapabilities", {businessId, centsCustomerId}],
      queryFn: async () => {
        if (!businessId || !centsCustomerId) {
          return {
            hasSelfServe: false,
            hasDelivery: false,
          };
        }

        const {data} = await fetchBusinessCapabilities(businessId);
        return data;
      },
      onError: (error) => {
        Sentry.captureException(error);
        createErrorToast({
          primaryMessage:
            error?.response?.data?.error ??
            EN_LOCALE.business.errors.businessCapabilitiesCouldNotBeFetched,
        });

        return error;
      },
    }
  );

  return {isSuccess, data};
};
