import {FC, useState} from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {type PaymentMethod} from "@stripe/stripe-js";
import {Button} from "components/common/buttons/Button";
import useRecaptchaToken from "hooks/useRecaptchaToken";
import {FULL_NAME_REGEXP, ZIP_CODE_REGEXP} from "constants/constants";
import {IconCalendar, IconCashCard, IconLock, IconUser, ZipCode} from "assets/images";
import FormTextField, {StripeFormTextField} from "./FormTextField";
import "./NewCardForm.styles.scss";

interface NewCardFormProps {
  onSave: ({
    payment,
    rememberPaymentMethod,
  }: {
    payment?: PaymentMethod;
    rememberPaymentMethod: boolean;
  }) => void;
}

const NewCardForm: FC<NewCardFormProps> = ({onSave}) => {
  const stripe = useStripe();
  const elements = useElements();

  const recaptchaToken = useRecaptchaToken("AddPaymentMethod");
  const [paymentTokenError, setPaymentTokenError] = useState<string | null | undefined>(
    ""
  );
  const [zipCode, setZipCode] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isValidZipCode = ZIP_CODE_REGEXP.test(zipCode);
  const isValidNameOnCard = FULL_NAME_REGEXP.test(nameOnCard);
  const isFormValid = isValidZipCode && isValidNameOnCard;

  const handleZipCode = (value: string) => {
    const formattedZipCode = value.replace(/[^0-9]+/g, "");

    if (formattedZipCode.length <= 5) {
      setZipCode(formattedZipCode);
    }
  };

  const handleNameOnCard = (value: string) => {
    setNameOnCard(value.replace(/\d/, ""));
  };

  const trimNameOnCard = () => {
    setNameOnCard((prev) => prev.trim());
  };

  const getPaymentToken = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (!recaptchaToken) {
      return setPaymentTokenError(
        "You are not authorized to perform this action. Please log out and try again."
      );
    }

    const cardElement = elements.getElement(CardNumberElement);
    try {
      setIsLoading(true);
      const payload = await stripe.createPaymentMethod({
        type: "card",
        // @ts-expect-error version conflict between stripe and react
        card: cardElement,
        billing_details: {
          address: {
            postal_code: zipCode,
          },
          name: nameOnCard,
        },
      });

      if (payload.error) {
        setIsLoading(false);
        return setPaymentTokenError(payload?.error?.message);
      }

      await onSave({
        payment: payload.paymentMethod,
        rememberPaymentMethod: true,
      });
    } catch (error: any) {
      let message;
      if (error) {
        message = error?.response?.data?.error;
      }
      setPaymentTokenError(message || "Something went wrong while saving the card");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="new-card-form">
      <FormTextField
        label="Name on card"
        startIcon={IconUser}
        value={nameOnCard}
        setValue={handleNameOnCard}
        onBlur={trimNameOnCard}
        error={!!(!isValidNameOnCard && nameOnCard.length)}
      />
      <StripeFormTextField
        label="Card Number"
        startIcon={IconCashCard}
        component={CardNumberElement}
      />

      <div className="flex-box">
        <StripeFormTextField
          label="MM/YY"
          startIcon={IconCalendar}
          component={CardExpiryElement}
          className="small"
        />

        <StripeFormTextField
          label="CVC"
          startIcon={IconLock}
          component={CardCvcElement}
          className="small"
        />
      </div>

      <FormTextField
        label="ZIP Code"
        startIcon={ZipCode}
        value={zipCode}
        setValue={handleZipCode}
        error={Boolean(!isValidZipCode && zipCode.length)}
        type="decimal"
        inputMode="decimal"
      />

      {paymentTokenError && <p className="payment-token-error">{paymentTokenError}</p>}

      <Button
        onClick={getPaymentToken}
        disabled={isLoading || !isFormValid}
        isLoading={isLoading}
      >
        Save
      </Button>
    </form>
  );
};

export default NewCardForm;
