import {type IOrderData, ServiceCategoryTypes} from "../types";
import {getTurnAroundsFromState} from "./getTurnAroundsFromState";

type SetCategorySelection = (data: {
  state: IOrderData;
  newValue: boolean;
  categoryType: ServiceCategoryTypes.DRY_CLEANING | ServiceCategoryTypes.LAUNDRY;
}) => void;

export const setCategorySelection: SetCategorySelection = ({
  state,
  newValue,
  categoryType,
}) => {
  const turnArounds = getTurnAroundsFromState(state);

  if (newValue) {
    state.orderBuilder.services.selectedCategories.push({
      name: categoryType,
      turnAround: turnArounds[categoryType],
    });
  } else {
    state.orderBuilder.services.selectedCategories =
      state.orderBuilder.services.selectedCategories.filter(
        ({name}) => name !== categoryType
      );
  }

  // Deprecated State Structure Compatibility providing
  state.schedule.turnAround = Math.max(
    ...state.orderBuilder.services.selectedCategories.map(({turnAround}) => turnAround)
  );
};
