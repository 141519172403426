import {useState, useEffect} from "react";
import {useStripe} from "@stripe/react-stripe-js";
import {type PaymentRequest, type PaymentMethod} from "@stripe/stripe-js";
import {ApplePayIcon, GooglePayIcon} from "assets/images";
import styles from "./WalletPaySelection.module.scss";

interface WalletPaySelectionProps {
  saveWalletPaymentMethod: (data: PaymentMethod) => void;
  label?: string;
  amount?: number;
  insideNewCardForm: boolean;
}

const WalletPaySelection = (props: WalletPaySelectionProps) => {
  const {saveWalletPaymentMethod, insideNewCardForm = true, amount, label} = props;
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(null);
  const [paymentRequestType, setPaymentRequestType] = useState<string>("");

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: label ?? "Add a Card",
          amount: amount ?? 0,
          pending: false,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          const browser = result.applePay ? "Buy with Apple Pay" : "Google Pay";
          setPaymentRequestType(browser);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, label, amount]);

  /**
   * Listen for paymentRequest changes for when Apple/Google Pay card is selected
   */
  useEffect(() => {
    paymentRequest?.on("paymentmethod", function (event) {
      const {id, card} = event.paymentMethod as PaymentMethod;

      if (!card || !id) {
        event.complete("fail");
      }

      saveWalletPaymentMethod(event.paymentMethod as PaymentMethod);
      event.complete("success");
    });
  }, [paymentRequest, saveWalletPaymentMethod]);

  const renderWalletPaySelection = () => {
    if (paymentRequest) {
      return (
        <div className={styles.walletPayLayout}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              paymentRequest.show();
            }}
            className={
              insideNewCardForm
                ? styles.walletPayButtonContainer
                : styles.walletPayColumnContainer
            }
          >
            <img
              src={paymentRequestType === "Apple Pay" ? ApplePayIcon : GooglePayIcon}
              alt={"Digital Wallet payment icon"}
            />
            <p className={styles.walletPayButtonText}>{paymentRequestType}</p>
          </div>
        </div>
      );
    }
  };

  return <>{renderWalletPaySelection()}</>;
};

export default WalletPaySelection;
