import {useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {withLDProvider} from "launchdarkly-react-client-sdk";
import isEmpty from "lodash/isEmpty";
import TagManager from "react-gtm-module";
import {ToastContainer, Slide, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {A11yButton} from "components/common/buttons/A11yButton";
import {ThemeProvider} from "components/common/providers/ThemeProvider";
import {ThirdPartiesProvider} from "components/common/providers/ThirdPartiesProvider";
import {checkAndroidWebView} from "utils/checkAndroidWebView";
import {initSentryWithFullstory} from "third-party/sentry";
import {ACCESS_TOKEN_KEY} from "constants/auth";
import "./App.scss";
import ErrorBoundary from "./ErrorBoundary";
import {Routes} from "./Routes";
import {ExitIcon} from "./assets/images";
import {Loader} from "./components/common";
import useCustomerState from "./hooks/useCustomerState";
import {CUSTOMER_KEY, CUSTOMER_AUTH_TOKEN_KEY} from "./utils";
import {getParsedLocalStorageData, isIOSDevice} from "./utils/common";
import {GTM_ID, ENVIRONMENT} from "./utils/config";

initSentryWithFullstory();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  if (ENVIRONMENT === "production") {
    const tagManagerArguments = {
      gtmId: GTM_ID,
    };
    TagManager.initialize(tagManagerArguments);
  }

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const onResize = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      // Reference: http://stackoverflow.com/a/9039885/177710
      if (/android/i.test(userAgent)) {
        window.addEventListener("resize", () => {
          if (document.activeElement.tagName === "INPUT") {
            window.setTimeout(function () {
              document.activeElement.scrollIntoView();
            }, 0);
          }
        });
      }
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  //https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
  useEffect(() => {
    const disableIOSTextFieldZoom = () => {
      if (!isIOSDevice()) {
        return;
      }

      const element = document.querySelector("meta[name=viewport]");
      if (element !== null) {
        let content = element.getAttribute("content");
        /* eslint-disable no-useless-escape */
        const scalePattern = /maximum\-scale=[0-9\.]+/g;
        if (scalePattern.test(content)) {
          content = content.replace(scalePattern, "maximum-scale=1.0");
        } else {
          content = [content, "maximum-scale=1.0"].join(", ");
        }
        element.setAttribute("content", content);
      }
    };

    disableIOSTextFieldZoom();
  }, []);

  const {setCustomerState} = useCustomerState();
  const [settingCustomerState, setSettingCustomerState] = useState(true);

  useEffect(() => {
    setSettingCustomerState(true);
    const customer = getParsedLocalStorageData(CUSTOMER_KEY);
    const customerAuthToken =
      localStorage.getItem(ACCESS_TOKEN_KEY) ||
      getParsedLocalStorageData(CUSTOMER_AUTH_TOKEN_KEY);
    setCustomerState({
      customer,
      customerAuthToken: isEmpty(customerAuthToken) ? null : customerAuthToken,
    });
    setSettingCustomerState(false);
  }, [setCustomerState]);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ThirdPartiesProvider>
            {settingCustomerState ? <Loader /> : <Routes />}
            <ToastContainer
              newestOnTop
              hideProgressBar
              autoClose={4000}
              transition={Slide}
              position={toast.POSITION.TOP_CENTER}
              closeButton={(props) => (
                <A11yButton
                  onAction={props.closeToast}
                  aria-label={props.ariaLabel}
                  className="Toastify__close-button-customized"
                >
                  <img src={ExitIcon} alt="Close" />
                </A11yButton>
              )}
            />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ThirdPartiesProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_KEY,
  /**
   * In cases where the Live Link Mobile App on Android calls the `identify` method,
   * errors may occur in the EventSource API network,
   * which Android WebView identifies as a resource access error,
   * which causes WebView to reload and cause problems for customers.
   *
   * This `streaming` value is responsible for loading Feature Flags in real-time,
   * but since we have a relatively small number of Feature Flags in Live Link
   * and they don't change frequently, we are disabling this feature
   * with `streaming: false` for Android WebView.
   *
   * So we get actual feature flags when the `identify` method is called
   * (when the application is opened or context change),
   * but do not instantly update them when their values change on the Launchdarkly side.
   */
  options: checkAndroidWebView() ? {streaming: false} : undefined,
})(App);
