import {
  checkBusinessAssetsInSessionStorage,
  checkOOBO,
  checkOOBOUser,
  getCurrentBusinessAssets,
} from "utils/ooboHelpers";
import {requestOtp} from "api/auth";

const useSkipOTP = (showOTPScreen: boolean = false) => {
  const {customerPhone} = getCurrentBusinessAssets();

  const isOOBOEnabled = checkOOBO();
  const isOOBOUser = checkOOBOUser();
  const isOOBOAssets = checkBusinessAssetsInSessionStorage();

  const isSkipOTPAsAdmin = isOOBOEnabled && showOTPScreen;
  const isSkipOTPAsKnownUser = isOOBOEnabled && showOTPScreen && isOOBOUser;
  const isSkipPhoneEntryAsKnownUser = isOOBOEnabled && !showOTPScreen && isOOBOUser;

  const presetOTPCode = async ({
    setOTP,
    phoneNumber,
    storeId,
  }: {
    setOTP: (otp: string) => void;
    phoneNumber: number | string;
    storeId: number | string | null;
  }): Promise<void> => {
    try {
      const res = await requestOtp({phoneNumber, storeId, isAuthorized: true});
      setOTP(res.data.otpCode);
    } catch (error) {
      console.error(error as Error);
    }
  };

  return {
    isOOBOEnabled,
    isOOBOUser,
    isSkipOTPAsAdmin,
    isSkipOTPAsKnownUser,
    isSkipPhoneEntryAsKnownUser,
    isOOBOAssets,
    presetOTPCode,
    customerPhone,
  };
};
export default useSkipOTP;
