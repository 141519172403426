// Fullstory (https://www.fullstory.com/) is used to capture user sessions and provide analytics on user behavior
// Fullstory is initialized in every environment - it is configured on the fullstory side to whitelist only the production domain
import * as FullStory from "@fullstory/browser";
import {FULLSTORY_ORG_ID} from "utils/config";

export function initFullstory() {
  FullStory.init({orgId: FULLSTORY_ORG_ID});
}

export default FullStory;
