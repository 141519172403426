import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchCustomerInfo} from "api/online-order";
import {type ICustomer} from "types/customer";
import {FETCH_CUSTOMER_INFO, CUSTOMER_REDUCER_NAME} from "../constants/actions";

export interface ICustomerInfo {
  customer: ICustomer;
  success: boolean;
}

export const fetchCustomerInfoThunk = createAsyncThunk(
  `${CUSTOMER_REDUCER_NAME}/${FETCH_CUSTOMER_INFO}`,
  async (
    {
      storeId,
    }: {
      storeId: number | string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetchCustomerInfo(storeId);
      return response.data as ICustomerInfo;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
