import {useState} from "react";
import {Button, Grid} from "@material-ui/core";
import {CalendarPicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {PickersDay} from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import {orderThemeColors, defaultFontFamily} from "components/online-order/orderTheme";
import {useWindowsPerDay} from "hooks/schedule/useWindowsPerDay";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {SCHEDULE_BUTTONS} from "constants/order";

const renderDay = (day, _value, dayComponentProps) => {
  return (
    <PickersDay
      {...dayComponentProps}
      sx={{
        fontSize: 14,
        color: orderThemeColors.textMain,
        fontFamily: defaultFontFamily,
        margin: "5px",
      }}
    />
  );
};

export const ScheduleCalendar = ({onClose, currentDate, isPickup}) => {
  const dispatch = useAppDispatch();

  const [singleDate, setSingleDate] = useState(dayjs(currentDate));

  const {deliveryDays} = useWindowsPerDay();

  const onConfirmClick = () => {
    const selectedDayIndex = deliveryDays.findIndex((possibleDay) =>
      possibleDay.date.includes(singleDate.format("YYYY-MM-DD"))
    );
    dispatch(
      isPickup
        ? orderActions.setPickupDayIndex(selectedDayIndex)
        : orderActions.setReturnDayIndex(selectedDayIndex)
    );
    onClose();
  };

  return (
    <div className="calendar-dialog">
      <div className="dates-picker-wrapper">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarPicker
            date={singleDate}
            onChange={setSingleDate}
            disablePast
            value={singleDate}
            maxDate={deliveryDays[deliveryDays.length - 1].date}
            shouldDisableDate={(day) => {
              const deliveryDay = deliveryDays.find((possibleDay) =>
                possibleDay.date.includes(day.format("YYYY-MM-DD"))
              );
              return !deliveryDay?.isAvailableDay;
            }}
            renderDay={renderDay}
            views={["day"]}
          />
        </LocalizationProvider>
      </div>
      <div className="all-dates-container">
        <Grid container className="all-dates-controls">
          <Button color="primary" variant="outlined" className="cancel" onClick={onClose}>
            {SCHEDULE_BUTTONS.cancel}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="confirm"
            onClick={onConfirmClick}
          >
            {SCHEDULE_BUTTONS.confirm}
          </Button>
        </Grid>
      </div>
    </div>
  );
};
