import {FC} from "react";
import phoneNumberScreenTestids from "tests/mocks/testids/PhoneNumberScreen.testids";
import {Button} from "components/common/buttons/Button";
import {VerifiedCustomer} from "components/verify-account/PhoneNumberScreen/PhoneNumberScreen";
import {EN_LOCALE} from "locales/en";
import {Phone} from "assets/images";
import styles from "./customerCard.module.scss";

export const CustomerCard: FC<
  {
    verifiedCustomer: VerifiedCustomer;
    isOOBOEnabled: boolean;
    isValidEmail: boolean;
    isAuthorized: boolean;
    sendVerificationHandler: () => void;
  } & Pick<VerifiedCustomer, "firstName" | "lastName">
> = ({
  verifiedCustomer,
  isAuthorized,
  isOOBOEnabled,
  firstName,
  lastName,
  isValidEmail,
  sendVerificationHandler,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <div>
          <p className={styles.welcomeMessage}>
            {verifiedCustomer?.isVerified
              ? `${EN_LOCALE.messages.welcomeBack} ${verifiedCustomer?.firstName}`
              : EN_LOCALE.messages.accountVerification}
          </p>
          {!isOOBOEnabled && (
            <p className={styles.details}>
              {verifiedCustomer?.isVerified
                ? EN_LOCALE.messages.loginRequirement
                : EN_LOCALE.messages.otpInstruction}
            </p>
          )}
        </div>
        <img src={Phone} width={52} height={83} alt={EN_LOCALE.label.phone} />
      </div>

      <Button
        onClick={sendVerificationHandler}
        disabled={
          (!verifiedCustomer?.isVerified && (!firstName || !lastName || !isValidEmail)) ||
          (isAuthorized ? false : Boolean(isAuthorized))
        }
        data-testid={phoneNumberScreenTestids.sendVerificationButton}
        id={styles.submitButton}
      >
        {isOOBOEnabled
          ? EN_LOCALE.button.continue
          : isAuthorized
            ? EN_LOCALE.button.showVerificationCode
            : EN_LOCALE.button.sendVerificationCode}
      </Button>
    </div>
  );
};
