import PropTypes from "prop-types";
import {Box, Button, Flex, Image, Text} from "rebass/styled-components";
import {useBranding} from "hooks/branding/useBranding";
import {EN_LOCALE} from "locales/en";
import {Unavailable, XIcon} from "assets/images";
import {Loader} from "./Loader";

const ScreenWrapper = (props) => {
  const {
    error,
    onClose,
    disableSubmit,
    onSubmit,
    submitText,
    loading,
    header,
    children,
    hideFooterButton,
    showFooterSecondaryButton,
    enableHeaderShadow,
    onCancel,
    fullScreenLoader = true,
  } = props;

  const {primaryColor, translucentPrimaryColor} = useBranding();
  const headerWrapperSize = "68px";
  const footerWrapperSize = hideFooterButton
    ? "16px"
    : showFooterSecondaryButton
      ? "145px"
      : "97px";

  return (
    <Flex
      {...styles.main.container}
      style={{
        "--theme-color": primaryColor,
        "--theme-secondary-color": translucentPrimaryColor,
      }}
    >
      <Flex {...styles.main.wrapper}>
        <div className="manage-order">
          {loading ? (
            <Loader
              width={styles.loading.width}
              style={styles.loading.style}
              fullScreen={fullScreenLoader}
            />
          ) : null}
          <Flex
            {...styles.header.wrapper}
            sx={{
              ...styles.header.wrapper.sx,
              boxShadow: enableHeaderShadow ? "0 0 3px rgb(0 0 0 / 25%)" : "none",
            }}
          >
            <Image
              src={XIcon}
              {...styles.header.image}
              onClick={onClose}
              width={28}
              height={28}
              alt={EN_LOCALE.label.xIcon}
            />
            <Text {...styles.header.text}>{header}</Text>
          </Flex>
          <Box
            {...styles.children.container}
            height={styles.children.container.height(
              headerWrapperSize,
              footerWrapperSize
            )}
          >
            <Box {...styles.children.wrapper}>
              {children}
              {error ? <Text variant="errorMessage">{error}</Text> : null}
            </Box>
          </Box>
          {!hideFooterButton ? (
            <div className="new-order-sticky-button">
              <Flex {...styles.footer.wrapper}>
                {showFooterSecondaryButton ? (
                  <Flex {...styles.footer.secondaryButtonWrapper} onClick={onCancel}>
                    <Unavailable fill={primaryColor} stroke={primaryColor} />
                    <Button variant="outline" {...styles.footer.secondaryButton}>
                      Cancel Order
                    </Button>
                  </Flex>
                ) : null}

                <Flex {...styles.footer.buttonWrapper}>
                  <Button
                    variant="primary"
                    {...styles.footer.button}
                    disabled={disableSubmit}
                    onClick={onSubmit}
                    data-testid="update-order-button"
                  >
                    {submitText}
                  </Button>
                </Flex>
              </Flex>
            </div>
          ) : null}
        </div>
      </Flex>
    </Flex>
  );
};

const styles = {
  // Wrapper Styles.
  main: {
    container: {
      flexDirection: "column",
      height: "calc(var(--app-height))",
      alignItems: "center",
    },
    wrapper: {
      width: ["100%", "100%", "100%", "75%", "50%"],
      flexDirection: "column",
      height: "calc(var(--app-height))",
    },
  },

  // Loader styles
  loading: {
    width: "100%",
    style: {
      zIndex: "999999999",
      width: "100vw",
      position: "fixed",
      top: "0",
      left: "0",
    },
  },

  // Header styles.
  header: {
    wrapper: {
      height: "67px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      sx: {
        zIndex: 49,
        position: "sticky",
        top: 0,
      },
    },
    text: {
      fontSize: "18px",
      fontWeight: 600,
      color: "NEW_TEXT_PRIMARY_BLUE",
    },
    image: {
      sx: {
        position: "absolute",
        top: "18px",
        left: "18px",
      },
    },
  },

  // Children Styles
  children: {
    container: {
      // total height - header - footer
      height: (header, footer) => `calc(var(--app-height) - ${header} - ${footer})`,
      overflow: "hidden auto",
    },
    // Adding some padding as this will give some space between
    // footer and the body
    wrapper: {
      pb: "16px",
      height: "calc(100% - 18px)",
    },
  },

  // Footer styles
  footer: {
    wrapper: {
      flexDirection: "column",
      padding: "16px",
      alignItems: "center",
      justifyContent: "center",
      bg: "WHITE",
      sx: {
        zIndex: 49,
        position: "sticky",
        bottom: 0,
        width: "100%",
      },
    },
    button: {
      p: "18.5px",
      width: "100%",
      textTransform: "uppercase",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.48px",
    },
    buttonWrapper: {
      width: "100%",
    },
    secondaryButtonWrapper: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingBottom: "16px",
      sx: {
        cursor: "pointer",
      },
    },
    secondaryButton: {
      textTransform: "uppercase",
      sx: {
        border: "none",
      },
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "14px",
      letterSpacing: "0.0125em",
      height: "32px",
    },
    secondaryButtonImage: {
      width: "24px",
      height: "24px",
      color: "primary",
    },
  },
};

ScreenWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  submitText: PropTypes.string,
  error: PropTypes.string,
  hideFooterButton: PropTypes.bool,
  showFooterSecondaryButton: PropTypes.bool,
  enableHeaderShadow: PropTypes.bool,
  fullScreenLoader: PropTypes.bool,
};

ScreenWrapper.defaultProps = {
  onCancel: () => {},
  loading: false,
  disableSubmit: false,
  submitText: "SUBMIT",
  error: null,
  hideFooterButton: false,
  showFooterSecondaryButton: false,
  enableHeaderShadow: false,
};

export default ScreenWrapper;
