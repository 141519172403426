import {createAsyncThunk} from "@reduxjs/toolkit";
import {AUTH_REDUCER_NAME, SIGN_UP} from "state/redux/slices/auth/constants/actions";
import {signUp, type SignUpResponse, type SignUpBody} from "api/auth/signUp";

export const signUpThunk = createAsyncThunk<SignUpResponse, SignUpBody>(
  `${AUTH_REDUCER_NAME}/${SIGN_UP}`,
  async ({otp, email, fullName, optOutMarketingSms}, thunkApi) => {
    try {
      const response = await signUp({otp, email, fullName, optOutMarketingSms});
      return response?.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
