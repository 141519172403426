import {useState} from "react";
import {useSelector} from "react-redux";
import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {
  getCurrentCustomerAddress,
  getNearStoresData,
} from "state/redux/slices/order/selectors";
import {DELIVERY_PROVIDERS} from "constants/order";
import {IAddress} from "types/customer";
import {useEstimateAndSetDelivery} from "./useEstimateAndSetDelivery";

interface IArgs {
  setIsMainButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isPickup: boolean;
}

export const useChooseTime = ({setIsMainButtonDisabled, isPickup}: IArgs) => {
  const dispatch = useAppDispatch();
  const {data: availableStores} = useSelector(getNearStoresData);
  const customerAddress = useSelector(getCurrentCustomerAddress) as IAddress;
  const customerAddressId = customerAddress?.id;
  const [isEstimating, setIsEstimating] = useState(false);

  const {estimateAndSetDelivery} = useEstimateAndSetDelivery({
    setIsEstimating,
    customerAddressId,
  });

  const chooseTime = async (windowData: ScheduleWindow) => {
    const timeZone = availableStores?.deliveryDays[0]?.timeZone;
    const zipCode = availableStores?.deliveryDays[0]?.customerZipCode;
    setIsMainButtonDisabled(false);

    let isSuccessful = true;

    if (!isPickup && windowData === null) {
      dispatch(orderActions.setReturnInfo({}));
      return isSuccessful;
    }

    if (windowData.type && windowData.type !== DELIVERY_PROVIDERS.ownDriver) {
      const {isSuccessful: isEstimateSuccessful} = await estimateAndSetDelivery({
        windowData: {...windowData, isPickup},
        timeZone,
        zipCode,
      });
      isSuccessful = isEstimateSuccessful;
    } else {
      if (isPickup) {
        dispatch(
          orderActions.setPickupInfo({
            timeZone,
            zipCode,
            isPickup: true,
            ...windowData,
          })
        );
      } else {
        dispatch(orderActions.setReturnInfo({timeZone, zipCode, ...windowData}));
      }
    }

    return isSuccessful;
  };

  return {chooseTime, isEstimating};
};
