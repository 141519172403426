import {FocusEventHandler, KeyboardEventHandler} from "react";

export interface IRequestParams {
  limit: number;
  offset: number;
}

export enum FetchingStatus {
  Initial,
  Pending,
  Fulfilled,
  Rejected,
}

export interface ISharedReducer {
  fetchingStatus: FetchingStatus;
  error: {
    text?: string;
    code?: string;
  };
}

export interface IGenericReducer<Type> extends ISharedReducer {
  data: Type;
}

export interface IGenericResponseData<Type> {
  items: Type[];
  totalCount: number;
}

export interface IGenericListData<Type> extends IGenericResponseData<Type> {
  limit: number;
  offset: number;
}

export type IGetInputProps = <InputProps extends object>(
  options?: InputProps | undefined
) => {
  type: "text";
  autoComplete: "off";
  role: "combobox";
  "aria-autocomplete": "list";
  "aria-expanded": boolean;
  "aria-activedescendant": string | undefined;
  disabled: boolean;
  onKeyDown: KeyboardEventHandler;
  onBlur: FocusEventHandler;
  value: string | undefined;
  onChange: (ev: {target: {value: string}}) => void;
} & InputProps;

export type EmptyObject = Record<string, never>;
