//Test only purposes
import {TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTime} from "luxon";
import {IS_TESTING_ENVIRONMENT} from "utils";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";

export const ManualDeliveryTimeInput = () => {
  const dispatch = useAppDispatch();
  const {pickup, returnInfo} = useAppSelector(orderSelectors.getScheduleDetails);
  const isShowingManualTimeInputs = IS_TESTING_ENVIRONMENT === "TRUE";

  return isShowingManualTimeInputs ? (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="Pickup Time Picker"
          value={DateTime.fromISO(String(pickup?.startTime))}
          onChange={(newValue) => {
            const newValueMillis = newValue?.valueOf() || 0;
            dispatch(
              orderActions.setPickupInfo({
                ...pickup,
                startTime: DateTime.fromMillis(newValueMillis).toISO(),
                endTime: DateTime.fromMillis(newValueMillis).toISO(),
                estimate: undefined,
              })
            );
          }}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="Delivery Time Picker"
          value={DateTime.fromISO(String(returnInfo?.startTime))}
          onChange={(newValue) => {
            const newValueMillis = newValue?.valueOf() || 0;
            dispatch(
              orderActions.setReturnInfo({
                ...returnInfo,
                startTime: DateTime.fromMillis(newValueMillis).toISO(),
                endTime: DateTime.fromMillis(newValueMillis).toISO(),
                estimate: undefined,
              })
            );
          }}
        />
      </LocalizationProvider>
    </>
  ) : (
    <></>
  );
};
