import {useCallback, useEffect, useState} from "react";
import {addTimeToMachine} from "features/order/self-serve/api/selfServe";
import {IAddTimePayload, ITopOffData, TopOffModes} from "features/order/self-serve/types";
import AvailableCreditWrapper from "features/payment/availableCredit/components/wrapper/Wrapper";
import {toast} from "react-toastify";
import {Button, Card, Flex, Heading, Image, Text} from "rebass/styled-components";
import {MachineEntity} from "types";
import {DockModal, Loader, ToastError} from "components/common";
import TimeCounter from "components/common/timeCounter/TimeCounter";
import {DeviceStatusPusherResponse} from "components/self-order/types";
import {formatCurrency} from "utils/payment";
import pluralize from "utils/pluralize";
import type {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {AddTimeServiceTypes, MAX_ADD_TIME, MachineManufacturers} from "constants/index";
import {EN_LOCALE} from "locales/en";
import {DateAndTimeWhiteIcon, UnloadedWashingMachine} from "assets/images";
import {ApiError} from "types/api";
import {ICustomer} from "types/customer";
import styles from "./styles";

interface IProps {
  isOpen: boolean;
  timeRemainingSeconds: number;
  machine: MachineEntity;
  topOffData?: ITopOffData | null;
  customer: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  storeId?: number;
  totalTurnTimeInMinutes?: number;
  manufacturer?: MachineManufacturers | null;
  isGPIOMachine: boolean;
  toggle: () => void;
  turnId?: number;
  balanceInDollars?: number | null;
  refetchBalance: UseAddCreditsPayload["refetch"];
  deviceSettings: DeviceStatusPusherResponse | null;
}

const AddTimeModal = ({
  isOpen,
  timeRemainingSeconds,
  machine,
  topOffData,
  customer,
  storeId,
  totalTurnTimeInMinutes,
  manufacturer,
  isGPIOMachine,
  toggle,
  turnId,
  balanceInDollars,
  refetchBalance,
  deviceSettings,
}: IProps) => {
  const {vendRemaining, deviceId} = deviceSettings || {};
  const [topOffQuantity, setTopOffQuantity] = useState<number>(1);
  const [currAvailableCredit, setCurrAvailableCredit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showAvailableCreditModal, toggleShowAvailableCreditModal] = useState(false);
  const timeRemainingInMinutes = Math.ceil(timeRemainingSeconds / 60);

  const manufacturersThatAllowExplicitTopOff = [
    MachineManufacturers.ACA,
    MachineManufacturers.DEXTER,
  ];

  const isItManufacturerThatAllowExplicitTopOff =
    manufacturersThatAllowExplicitTopOff.includes(manufacturer as MachineManufacturers) ||
    isGPIOMachine;

  const serviceType = isItManufacturerThatAllowExplicitTopOff
    ? AddTimeServiceTypes.ADD_TIME
    : AddTimeServiceTypes.ADD_ADDITIONAL_VEND;
  const totalTimeToAddInMinutes =
    Number(topOffData?.topOffTimeInMinutes) * topOffQuantity;
  const buttonPostfix = isItManufacturerThatAllowExplicitTopOff
    ? ` for ${pluralize("minute", totalTimeToAddInMinutes)}`
    : "";

  const totalPriceInCents = isItManufacturerThatAllowExplicitTopOff
    ? topOffQuantity * Number(topOffData?.topOffPriceInCents)
    : Number(vendRemaining);

  const turnTotalTimeExceeded =
    Number(totalTurnTimeInMinutes) + totalTimeToAddInMinutes > MAX_ADD_TIME;

  // for ACA / Dexter, we know the TopOffData and details within TopOffData
  // so here, the logic for using TopOffData and for the "total time exceeded" checks
  // still apply and should remain the same for these manufacturers
  const validateAddTimeForACAOrDexter =
    [TopOffModes.FULLCYCLE, TopOffModes.PARTIAL].includes(
      topOffData?.topOffMode as TopOffModes
    ) &&
    !turnTotalTimeExceeded &&
    totalPriceInCents / 100 <= currAvailableCredit;

  // for manufacturers like Midas (or MDC/Centurion, potentially), we only know if top off was added
  // via the vendRemaining value from the Device changes via Pusher
  // so, if the turn is indeed running and the vendRemaining is now greater than 0, then top off has been added
  const validateAddTimeWhereTopOffDataIsMissing =
    !!totalPriceInCents &&
    totalPriceInCents > 0 &&
    totalPriceInCents / 100 <= currAvailableCredit;

  const isAvailableToAddTime = isItManufacturerThatAllowExplicitTopOff
    ? validateAddTimeForACAOrDexter
    : validateAddTimeWhereTopOffDataIsMissing;

  const shouldDisplayTimeCounter =
    isItManufacturerThatAllowExplicitTopOff &&
    [TopOffModes.FULLCYCLE, TopOffModes.PARTIAL].includes(
      topOffData?.topOffMode as TopOffModes
    ) &&
    !!topOffData?.topOffPriceInCents &&
    !!topOffData?.topOffTimeInMinutes;

  useEffect(() => {
    setCurrAvailableCredit(balanceInDollars ?? 0);
  }, [balanceInDollars]);

  const handleAddTime = async () => {
    if (!isAvailableToAddTime) return;

    try {
      setIsLoading(true);
      const topOffPriceInCents = isItManufacturerThatAllowExplicitTopOff
        ? Number(topOffData?.topOffPriceInCents)
        : Number(vendRemaining);

      const payload: IAddTimePayload = {
        machineId: machine.id,
        deviceId: deviceId as number,
        serviceType,
        topOffPriceInCents,
        topOffQuantity,
        topOffTimeInSeconds: Number(topOffData?.topOffTimeInSeconds) || null,
        turnId: turnId as number,
      };
      const response = await addTimeToMachine(payload);
      if (response) {
        toast.success("You have added time to the laundry machine!");
        toggle();
      }
    } catch (error) {
      const err = error as ApiError;
      toast.error(<ToastError message={err?.response?.data?.error || err?.message} />);
    } finally {
      setIsLoading(false);
    }
  };

  const onSetCurrAvailableCredit = useCallback(
    (credits: number) => {
      setCurrAvailableCredit(credits);
      if (refetchBalance) {
        refetchBalance();
      }
    },
    [setCurrAvailableCredit, refetchBalance]
  );

  return (
    <DockModal
      header="Add Time"
      isOpen={isOpen}
      toggle={toggle}
      fullWidth
      size={1}
      dockZindex={999}
      showExitIcon
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {timeRemainingInMinutes ? (
            <Flex sx={styles.timeRemainingContainer}>
              <Image
                src={DateAndTimeWhiteIcon}
                sx={styles.dateAndTimeIcon}
                alt={EN_LOCALE.label.clock}
              />
              <Text>{pluralize("min", timeRemainingInMinutes)} remaining</Text>
            </Flex>
          ) : null}

          <Card sx={styles.cardContainer}>
            <Flex justifyContent="space-between">
              <Flex>
                <Flex flexDirection="column" alignItems="start">
                  <Heading sx={styles.machineName}>
                    {machine.prefix}-{machine.name}
                  </Heading>
                  {shouldDisplayTimeCounter && (
                    <>
                      <Text sx={styles.timeCounterLabel}>Add more time:</Text>
                      <TimeCounter
                        initialTimeInMinutes={topOffData?.topOffTimeInMinutes as number}
                        addTimeInMinutes={topOffData?.topOffTimeInMinutes as number}
                        onQuantityChange={setTopOffQuantity}
                        withoutBaseTime
                      />
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex>
                <Image
                  src={UnloadedWashingMachine}
                  alt={EN_LOCALE.label.unloadedWashingMachine}
                />
              </Flex>
            </Flex>
          </Card>

          <AvailableCreditWrapper
            customer={customer}
            storeId={storeId ?? null}
            setCurrAvailableCredit={onSetCurrAvailableCredit}
            showModal={showAvailableCreditModal}
            toggleShowModal={toggleShowAvailableCreditModal}
            currAvailableCredit={currAvailableCredit}
            machine={machine}
          />

          <Flex sx={styles.buttonWrapper}>
            <Button
              disabled={!isAvailableToAddTime}
              variant="primary"
              sx={styles.button}
              onClick={handleAddTime}
            >
              PAY {formatCurrency(totalPriceInCents)}
              {buttonPostfix}
            </Button>
            <Text sx={styles.poweredByCents}>Powered by Cents</Text>
          </Flex>
        </>
      )}
    </DockModal>
  );
};

export default AddTimeModal;
